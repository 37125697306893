import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import { getCampaignConfiguration } from 'actions/campaign';
import {
  actionTypes as entitiesActionTypes,
  maybeFetchOntologies,
  maybeFetchTagSets,
  maybeFetchUsers,
} from 'actions/entities';
import { loadingStateSelector } from 'reducers/ui';
import { campaignConfigurationIsInitializedSelectorFactory } from 'selectors/campaign';
import { usersIsLoadingSelector } from 'selectors/entities';
import {
  campaignPresetsLoadingSelector,
  maybeLoadCampaignFilterPresets,
  resetPresetSelection,
} from 'store/monitor/campaignPresetSlice';
import { clearAllFilters } from 'store/monitor/monitorSearchSlice';

import BeatingLoader from 'components/ui/BeatingLoader';

import { useMemoizedFactorySelector } from 'utils/hooks';

function CampaignLoader() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const campaignConfigurationIsInitialized = useMemoizedFactorySelector(
    campaignConfigurationIsInitializedSelectorFactory,
    campaignId
  );

  const tagSetsAreLoading = useSelector(
    loadingStateSelector([entitiesActionTypes.FETCH_TAG_SETS_REQUEST])
  );
  const campaignPresetsLoading = useSelector(campaignPresetsLoadingSelector);
  const usersAreLoading = useSelector(usersIsLoadingSelector);

  const ontologiesAreLoading = useSelector(
    loadingStateSelector([entitiesActionTypes.FETCH_ONTOLOGIES_REQUEST])
  );
  useEffect(() => {
    dispatch(getCampaignConfiguration(campaignId));
    dispatch(maybeFetchTagSets());
    dispatch(maybeFetchOntologies());
    dispatch(maybeFetchUsers());
    dispatch(maybeLoadCampaignFilterPresets({ campaignId }));
    return () => {
      dispatch(clearAllFilters());
      dispatch(resetPresetSelection());
    };
  }, [campaignId]);

  const isLoading =
    !campaignConfigurationIsInitialized ||
    tagSetsAreLoading ||
    usersAreLoading ||
    ontologiesAreLoading ||
    campaignPresetsLoading;

  return isLoading ? <BeatingLoader /> : <Outlet />;
}

export default CampaignLoader;
