import PropTypes from 'prop-types';
import { Divider, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';

import * as svars from 'assets/style/variables';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${svars.spaceNormal};
`;

const CustomizationTabSegment = styled(Segment)`
  &&& {
    height: ${({ fitted }) => (fitted ? 'auto' : '100%')};
    min-width: 50rem;
    max-width: 100%;
    margin: ${svars.spaceMedium} auto;
    overflow: clip auto;
  }
`;

function TabSegmentTitle({ title, help }) {
  const header = (
    <Header
      as="h3"
      style={{ margin: '0 auto', marginRight: svars.spaceNormal }}
    >
      {title}
    </Header>
  );
  return (
    <>
      <TitleContainer>
        {help ? (
          <HelpTooltip
            iconSize={16}
            triggerText={header}
            position="top right"
            mouseEnterDelay={200}
            help={help}
          />
        ) : (
          { header }
        )}
      </TitleContainer>
      <Divider fitted style={{ marginBottom: svars.spaceMedium }} />
    </>
  );
}

TabSegmentTitle.propTypes = {
  title: PropTypes.string.isRequired,
  help: PropTypes.string,
};

TabSegmentTitle.defaultProps = {
  help: null,
};

export { TabSegmentTitle };

export default CustomizationTabSegment;
