import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { i18n } from '@lingui/core';

import { campaignAclSelectorFactory } from 'selectors/campaign';
import { isAdminSelector } from 'selectors/user';

import { pageCrumbFactory } from 'components/ui/navigation/PageBreadcrumbs';

import getCampaignSubRoutes, { campaignHomeRoute } from './campaignSubRoutes';

const getRoutesItems = (isAdmin) =>
  [campaignHomeRoute, ...getCampaignSubRoutes(isAdmin)].map(
    ({ to, ctaText, icon, active }) => ({
      i18nText: ctaText,
      id: to,
      icon,
      active,
    })
  );

export function CampaignPagesCrumb({ match, isLast }) {
  const currentLocation = useLocation();
  const { campaignId } = useParams();
  const isAdmin = useSelector(isAdminSelector);
  const acl = useSelector(campaignAclSelectorFactory(campaignId));
  const subRoutes = useMemo(
    () => getRoutesItems(acl?.a || isAdmin),
    [isAdmin, acl?.a]
  );

  const [campaignRouteName, currentId, crumbText, crumbIcon] = useMemo(() => {
    const [_, routeName, tabName] =
      currentLocation.pathname?.match(
        new RegExp(`${campaignId}/([a-zA-Z0-9-]+)(/.*)?`)
      ) || [];
    const selectedItem = routeName
      ? subRoutes?.find((route) => route.id === routeName)
      : null;
    const text = selectedItem ? i18n._(selectedItem.i18nText) : null;
    const icon = selectedItem?.icon;
    // currentId is the part of the route that will be replaced when navigating
    // tabName allows to redirect from nested route in one page to index page of another
    return [routeName, `${routeName}${tabName || ''}`, text, icon];
  }, [currentLocation?.pathname, campaignId]);

  return pageCrumbFactory(
    crumbText,
    true,
    subRoutes,
    campaignRouteName,
    crumbIcon
  )({ match, isLast, currentId });
}

export default CampaignPagesCrumb;
