import { useCallback } from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { QUESTION_TYPES } from 'selectors/survey';

import { LightHeader } from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { TabButton } from 'components/ui/button/TabButton';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const DropdownContainer = styled.div`
  max-height: 40vh;
  min-width: 28vw;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: ${svars.spaceNormal};
  padding-top: 0;
`;

const DropdownItemLabelContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
`;

const LightHeaderLabel = styled(LightHeader)`
  &&& {
    padding: 0;
    padding-right: ${svars.spaceMedium};
    margin: 0;
    display: flex;
    align-items: center;
    height: 25px;
  }
`;

const HoverableSegment = styled(Segment)`
  &&& {
    ${({ disabled }) =>
      !disabled
        ? `cursor: pointer;
            &:hover {
            background: ${svars.accentColorLightest};
        }`
        : 'opacity: 0.5;'}
`;

function SelectQuestionTypeDropdown({ onHandleSelect, onToggle, modalIsOpen }) {
  const onQuestionTypeSelect = useCallback(
    (questionType) => () => {
      onHandleSelect(null, questionType);
      onToggle();
    },
    [onHandleSelect, onToggle]
  );
  return (
    <Popup
      open={modalIsOpen}
      flowing
      trigger={
        <span
          style={{
            float: 'right',
            overflowX: 'hidden',
          }}
        >
          <TabButton fitted="true" active={modalIsOpen}>
            <Icon data-testid="bo-campaign-add-questions-icon" name="add" style={{ margin: 0 }} />
          </TabButton>
        </span>
      }
      content={
        <div
          style={{
            padding: `${svars.spaceMedium} ${svars.spaceNormalLarge}`,
          }}
        >
          <LightHeader>
            <Trans id="select-a-question-type" /> :
          </LightHeader>
          <DropdownContainer>
            {QUESTION_TYPES.map(
              ({ key, text, icon, description, disabled }) => (
                <HoverableSegment
                  disabled={disabled}
                  key={`fpsi-${key}`}
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                  }}
                  onClick={disabled ? null : onQuestionTypeSelect(key)}
                  data-testid={`${key}Button`}
                >
                  <DropdownItemLabelContainer>
                    <Icon
                      name={icon}
                      size="large"
                      style={{ marginRight: svars.spaceMedium }}
                    />
                    <LightHeaderLabel inline="true">
                      <span
                        style={{
                          whiteSpace: 'pre',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Trans render={capitalizedTranslation} id={text} />
                        {description ? <Trans id={description} /> : null}
                      </span>
                    </LightHeaderLabel>
                  </DropdownItemLabelContainer>
                  <Icon name="add" />
                </HoverableSegment>
              )
            )}
          </DropdownContainer>
        </div>
      }
      on="click"
      onClose={onToggle}
      onOpen={onToggle}
      position="bottom right"
      style={{ zIndex: 900, padding: 0 }}
    />
  );
}

SelectQuestionTypeDropdown.propTypes = {
  onHandleSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

SelectQuestionTypeDropdown.defaultProps = {};

export default SelectQuestionTypeDropdown;
