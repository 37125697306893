import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider, Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import {
  deleteQuestion,
  duplicateQuestion,
  reorderQuestionsDragAndDrop,
  setQuestionType,
  toggleDisplayHomeScreenElement,
} from 'actions/survey';
import {
  displayHomeScreenSelector,
  newQuestionSelector,
  surveyQuestionsSelector,
} from 'selectors/survey';

import { LightHeader } from 'components/ui/Header';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import { LimitedRichTextCell } from 'components/ui/Text';
import { TabButton } from 'components/ui/button/TabButton';
import DragAndDropList, {
  DraggableSegment,
} from 'components/ui/inputs/DragAndDropList';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import EndScreenSettings from './EndScreenSettings';
import HomeScreenSettings from './HomeScreenSettings';
import { EndScreenEditor, HomeScreenEditor } from './MetaPageEditors';
import QuestionEditor from './QuestionEditor';
import QuestionSettings from './QuestionSettings';
import SelectQuestionTypeDropdown from './SelectQuestionTypeDropdown';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: ${(props) => props.width};
  width: ${(props) => (props.width / 16) * 100}%;
  padding: ${svars.spaceMedium} 0;
  margin: 0 ${svars.spaceMedium};
`;

const LeftColumn = styled(Column)`
  background: ${svars.colorWhite};
  border-right: 1px solid ${svars.colorLightGrey};
  margin: 0;
  padding: 0;
`;

const RightColumn = styled(Column)`
  background: ${svars.colorWhite};
  border-left: 1px solid ${svars.colorLightGrey};
  margin: 0;
  padding: ${svars.spaceMedium} 0;
`;

const ElementContainer = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
`;

const getQuestionIcon = (type) => {
  switch (type) {
    case 'MultiChoiceSurveyQuestion':
      return 'list';
    case 'TextSurveyQuestion':
      return 'font';
    default:
      return 'question';
  }
};

const questionDraggableElementFactory = (
  onDuplicate,
  onDelete,
  deleteIsDisabled
) => {
  function QuestionDraggableElement({ id, index, type, title }) {
    return (
      <>
        <ElementContainer>
          <Icon
            style={{
              color: svars.accentColorClear,
              marginRight: svars.spaceNormal,
            }}
            name={getQuestionIcon(type)}
          />
          <span
            style={{
              display: 'inline-flex',
              flexShrink: 0,
              marginRight: svars.spaceNormal,
            }}
          >
            {index} .
          </span>
          <LimitedRichTextCell value={title} />
        </ElementContainer>
        <RowMenu
          upward={index > 2}
          items={[
            {
              content: t`duplicate`,
              icon: 'copy outline',
              onClick: onDuplicate(id),
            },
            {
              content: t`delete`,
              icon: 'delete',
              iconColor: 'red',
              disabled: deleteIsDisabled,
              onClick: onDelete(id),
            },
          ]}
          disabled={false}
        />
      </>
    );
  }
  QuestionDraggableElement.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };
  QuestionDraggableElement.defaultProps = {};
  return QuestionDraggableElement;
};

function MetaElementContainer({ label, icon, onClick, active }) {
  return (
    <DraggableSegment
      clickable="true"
      active={active ? 'true' : null}
      style={{
        margin: `0 ${svars.spaceNormal}`,
        width: `calc(100% - 2*${svars.spaceNormal})`,
      }}
      onClick={onClick}
    >
      <ElementContainer style={{ margin: svars.spaceMedium }}>
        <Icon
          size="big"
          style={{
            color: svars.accentColorClear,
            marginRight: svars.spaceNormal,
          }}
          name={icon}
        />
        <span
          style={{
            display: 'inline-flex',
            flexShrink: 0,
            marginRight: svars.spaceNormal,
          }}
        >
          <Trans id={label} />
        </span>
      </ElementContainer>
    </DraggableSegment>
  );
}

MetaElementContainer.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

const HeaderContainer = styled.div`
  padding: ${svars.spaceMedium} ${svars.spaceNormalLarge};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  ${({ clickable }) =>
    clickable
      ? css`
          ${svars.hoverClickableCss} ${svars.activeClickableCss}
        `
      : 'cursor: default;'}
`;

function NavigationSectionHeader({
  title,
  action,
  actionDisabled,
  onClick,
  active,
  navigationSectionTestId,
}) {
  return (
    <HeaderContainer
      active={active ? 'true' : null}
      onClick={onClick}
      clickable={onClick ? 'true' : null}
      data-testid={navigationSectionTestId}
    >
      <LightHeader
        inline="true"
        style={{ margin: `0 ${svars.spaceNormalLarge} 0 0` }}
      >
        <Trans id={title} render={capitalizedTranslation} />
      </LightHeader>
      {action || (
        <TabButton fitted="true" disabled={actionDisabled}>
          <Icon name="add" style={{ margin: 0 }} disabled />
        </TabButton>
      )}
    </HeaderContainer>
  );
}

NavigationSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.node,
  actionDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  navigationSectionTestId: PropTypes.string,
};

NavigationSectionHeader.defaultProps = {
  action: null,
  actionDisabled: false,
  onClick: null,
  active: false,
  navigationSectionTestId: undefined,
};

function CustomizeQuestionsTab() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [homeScreenEdition, setHomeScreenEdition] = useState(false);
  const [endScreenEdition, setEndPageEdition] = useState(false);
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] =
    useState(false);
  const newQuestion = useSelector(newQuestionSelector(campaignId));
  const displayHomeScreen = useSelector(
    displayHomeScreenSelector(campaignId, true)
  );
  const questions = useSelector(surveyQuestionsSelector(campaignId, true));
  useEffect(() => {
    if (questions?.length && !selectedQuestion) {
      setSelectedQuestion(questions[0]);
    }
  }, []);
  useEffect(() => {
    if (newQuestion && selectedQuestion?.id !== newQuestion?.id) {
      setSelectedQuestion(newQuestion);
    }
  }, [newQuestion]);
  const onSelectQuestion = useCallback(
    (selected) => {
      setSelectedQuestion(selected);
      if (homeScreenEdition) {
        setHomeScreenEdition(false);
      }
      if (endScreenEdition) {
        setEndPageEdition(false);
      }
    },
    [
      homeScreenEdition,
      endScreenEdition,
      setSelectedQuestion,
      setHomeScreenEdition,
      setEndPageEdition,
    ]
  );
  const onUpdateQuestions = useCallback((params) =>
    dispatch(reorderQuestionsDragAndDrop(campaignId, params))
  );
  const onDelete = useCallback(
    (questionId) => () => dispatch(deleteQuestion(campaignId, questionId)),
    []
  );
  const onDuplicate = useCallback(
    (questionId) => () => dispatch(duplicateQuestion(campaignId, questionId)),
    []
  );
  const onToggleQuestionCreateModal = useCallback(
    () => setIsQuestionCreateModalOpen(!isQuestionCreateModalOpen),
    [isQuestionCreateModalOpen]
  );
  const onCreateQuestion = useCallback(
    (questionId, questionType) => {
      dispatch(setQuestionType(campaignId, questionId, questionType)).then(
        () => {
          // Close home or end page edition
          if (homeScreenEdition) {
            setHomeScreenEdition(false);
          }
          if (endScreenEdition) {
            setEndPageEdition(false);
          }
        }
      );
    },
    [
      campaignId,
      dispatch,
      questions,
      setQuestionType,
      setSelectedQuestion,
      homeScreenEdition,
      endScreenEdition,
    ]
  );
  const onHomeScreenEdition = useCallback(() => {
    // Remove selected question
    if (selectedQuestion) {
      setSelectedQuestion(null);
    }
    if (endScreenEdition) {
      setEndPageEdition(false);
    }
    if (!homeScreenEdition) setHomeScreenEdition(true);
    if (!displayHomeScreen) {
      dispatch(toggleDisplayHomeScreenElement(campaignId, true));
    }
  }, [
    homeScreenEdition,
    endScreenEdition,
    selectedQuestion,
    displayHomeScreen,
  ]);
  const onEndScreenEdition = useCallback(() => {
    // Remove selected question
    if (selectedQuestion) {
      setSelectedQuestion(null);
    }
    if (homeScreenEdition) {
      setHomeScreenEdition(false);
    }
    if (!endScreenEdition) setEndPageEdition(true);
  }, [homeScreenEdition, endScreenEdition, selectedQuestion]);
  const onlyOnyItem = questions?.length === 1;
  const renderQuestionElement = useMemo(
    () => questionDraggableElementFactory(onDuplicate, onDelete, onlyOnyItem),
    [onDuplicate, onDelete, questions, onlyOnyItem]
  );
  return (
    <Container>
      <LeftColumn width={4}>
        <NavigationSectionHeader
          onClick={onHomeScreenEdition}
          active={homeScreenEdition}
          title={t`survey-landing-page`}
          navigationSectionTestId="bo-campaign-landing-page-button"
          action={
            <Icon
              name={displayHomeScreen ? 'cog' : 'plus'}
              style={{ margin: 0, marginRight: svars.spaceNormal }}
            />
          }
        />

        <Divider fitted />
        <NavigationSectionHeader
          title={t`questions`}
          action={
            <SelectQuestionTypeDropdown
              onHandleSelect={onCreateQuestion}
              onToggle={onToggleQuestionCreateModal}
              modalIsOpen={isQuestionCreateModalOpen}
            />
          }
        />

        <DragAndDropList
          isItemDisabled={() => onlyOnyItem}
          elements={questions}
          onChange={onUpdateQuestions}
          onRowClick={onSelectQuestion}
          selected={selectedQuestion}
          getElementId={(item) => item?.id}
          renderElement={renderQuestionElement}
          style={{ minHeight: '30vh' }}
        />
        <Divider fitted />
        <NavigationSectionHeader
          onClick={onEndScreenEdition}
          active={endScreenEdition}
          title={t`survey-ending-page`}
          navigationSectionTestId="bo-campaign-ending-page-button"
          action={
            <Icon
              name="cog"
              style={{ margin: 0, marginRight: svars.spaceNormal }}
            />
          }
        />
      </LeftColumn>
      <Column width={8}>
        <QuestionEditor questionId={selectedQuestion?.id} />
        {homeScreenEdition ? <HomeScreenEditor /> : null}
        {endScreenEdition ? <EndScreenEditor /> : null}
      </Column>
      <RightColumn width={4}>
        {selectedQuestion ? (
          <QuestionSettings
            questionId={selectedQuestion?.id}
            withLogic={selectedQuestion?.index > 1}
          />
        ) : null}
        {homeScreenEdition ? <HomeScreenSettings /> : null}
        {endScreenEdition ? <EndScreenSettings /> : null}
      </RightColumn>
    </Container>
  );
}

export default CustomizeQuestionsTab;
