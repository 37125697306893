import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Divider, Form } from 'semantic-ui-react';

import {
  END_SCREEN_ILLUSTRATION_OPTIONS,
  updateEndScreenElement,
  updateEndScreenIllustrationOption,
} from 'actions/survey';
import {
  ctaLabelEndScreenSelector,
  ctaUrlEndScreenSelector,
  endScreenIllustrationSelector,
  showCtaEndScreenSelector,
} from 'selectors/survey';

import Header, { LightHeader } from 'components/ui/Header';
import CustomizationInput from 'components/ui/inputs/CustomizationInput';
import RadioList from 'components/ui/inputs/RadioList';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';

import { validateUrl } from 'utils/dataValidation';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import TogglableSection from './TogglableSection';

function EndScreenSettings() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const showCta = useSelector(showCtaEndScreenSelector(campaignId, true));
  const ctaLabel = useSelector(ctaLabelEndScreenSelector(campaignId, true));
  const ctaUrl = useSelector(ctaUrlEndScreenSelector(campaignId, true));
  const endScreenIllustration = useSelector(
    endScreenIllustrationSelector(campaignId, true)
  );

  const onElementChange = useCallback(
    (key) => (value) => {
      dispatch(updateEndScreenElement(campaignId, key, value));
    },
    [dispatch, campaignId]
  );
  const onToggleIllustration = useCallback(
    (event, { checked }) => {
      dispatch(updateEndScreenIllustrationOption(campaignId, checked));
    },
    [dispatch, campaignId, endScreenIllustration]
  );
  const onIllustrationChange = useCallback(
    (value) => () => {
      dispatch(updateEndScreenIllustrationOption(campaignId, value));
    },
    [dispatch, campaignId]
  );
  const onActivateEndScreenCta = useCallback(
    (event, { checked }) => {
      dispatch(updateEndScreenElement(campaignId, 'show_button', checked));
    },
    [dispatch, campaignId]
  );

  return (
    <>
      <Form.Field
        style={{
          margin: `0 ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceMedium}`,
        }}
      >
        <Header>
          <Trans render={capitalizedTranslation} id="ending-settings" />
        </Header>
      </Form.Field>
      <Form.Field>
        <LightHeader>
          <TogglableSection
            checked={!!endScreenIllustration}
            onChange={onToggleIllustration}
            title={t`add-an-illustration`}
            subtitle={t`add-an-illustration-subtitle`}
            gaCategory="Campaign - customization"
            gaAction="Use ending illustration"
            gaLabel={`${campaignId}`}
            togglableSectionTestId="bo-campaign-add-illustration-checkbox"
          />
        </LightHeader>
        {endScreenIllustration ? (
          <RadioList
            options={END_SCREEN_ILLUSTRATION_OPTIONS}
            selected={endScreenIllustration}
            onChange={onIllustrationChange}
          />
        ) : null}
      </Form.Field>
      <Divider fitted />
      <Form.Field>
        <TogglableSection
          checked={showCta}
          onChange={onActivateEndScreenCta}
          title={t`add-a-cta`}
          subtitle={t`add-a-cta-subtitle`}
          gaCategory="Campaign - customization"
          gaAction="Use ending cta"
          gaLabel={`${campaignId}`}
          togglableSectionTestId="bo-campaign-add-a-call-to-action-checkbox"
        />
      </Form.Field>

      {showCta ? (
        <Form.Field style={{ margin: svars.spaceMedium }}>
          <CustomizationInput
            style={{ marginTop: svars.spaceMediumLarge }}
            label="button-label"
            placeholder="Read more"
            value={ctaLabel}
            onChange={onElementChange('button_label')}
            initialValue={ctaLabel}
            InputComponent={LimitedTextInput}
            maxCharacters={40}
          />
          <CustomizationInput
            style={{ marginTop: svars.spaceMediumLarge }}
            label="button-link"
            placeholder="https://company.com/privacy"
            value={ctaUrl}
            onChange={onElementChange('button_url')}
            validate={validateUrl}
            errorMessage={t`invalid-url`}
            resetValue={() =>
              onElementChange('button_url')(null, { value: '' })
            }
            initialValue={ctaUrl}
          />
        </Form.Field>
      ) : null}
    </>
  );
}

EndScreenSettings.propTypes = {};
EndScreenSettings.defaultProps = {};

export default EndScreenSettings;
