import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import { campaignAclSelectorFactory } from 'selectors/campaign';
import { isAdminSelector } from 'selectors/user';

import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { ButtonSecondaryAccent } from 'components/ui/button';
import { PageLayout } from 'components/ui/layout/Page';

import * as svars from 'assets/style/variables';

import getCampaignSubRoutes from './campaignSubRoutes';

const HomeItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${svars.spaceMedium};
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  &&&& button {
    min-width: 12rem;
  }
`;

function CampaignHomePage() {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const isAdmin = useSelector(isAdminSelector);
  const acl = useSelector(campaignAclSelectorFactory(campaignId));
  const onNavigate = useCallback((route) => () => navigate(route), []);
  const subRoutes = useMemo(
    () => getCampaignSubRoutes(acl?.a || isAdmin),
    [isAdmin, acl?.a]
  );
  return (
    <PageLayout compact padded>
      <Segment>
        {subRoutes
          .map(({ to, header, description, ctaText, icon }) => (
            <HomeItemContainer key={`${to}`}>
              <Header
                style={{ maxWidth: '400px', marginRight: svars.spaceMedium }}
              >
                <Trans id={header} />
                <Header.Subheader style={{ marginTop: svars.spaceNormalLarge }}>
                  <Trans id={description} />
                </Header.Subheader>
              </Header>
              <ButtonSecondaryAccent
                icon={icon}
                labelPosition="right"
                content={<Trans id={ctaText} />}
                onClick={onNavigate(to)}
                data-testid={`bo-campaign-homepage-cta-buttons-${to}`}
              />
            </HomeItemContainer>
          ))
          .reduce(
            (acc, item, iReduce) =>
              acc.length > 0
                ? // eslint-disable-next-line react/no-array-index-key
                  [...acc, <Divider key={`pcse-${iReduce}`} />, item]
                : [item],
            []
          )}
      </Segment>
    </PageLayout>
  );
}

CampaignHomePage.propTypes = {};
CampaignHomePage.defaultProps = { children: null };

export default CampaignHomePage;
