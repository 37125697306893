import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import { Form } from 'semantic-ui-react';

import {
  updateEndScreenElement,
  updateHomeScreenElement,
} from 'actions/survey';
import {
  descriptionEndScreenSelector,
  descriptionHomeScreenSelector,
  displayHomeScreenSelector,
  taglineHomeScreenSelector,
  titleEndScreenSelector,
  titleHomeScreenSelector,
} from 'selectors/survey';

import StyledSegment from 'components/ui/Segment';
import RichTextEditor from 'components/ui/inputs/RichTextEditor';

import * as svars from 'assets/style/variables';

export function HomeScreenEditor() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const tagline = useSelector(taglineHomeScreenSelector(campaignId, true));
  const title = useSelector(titleHomeScreenSelector(campaignId, true));
  const displayHomeScreen = useSelector(
    displayHomeScreenSelector(campaignId, true)
  );
  const description = useSelector(
    descriptionHomeScreenSelector(campaignId, true)
  );
  const onElementChange = useCallback(
    (key) => (value) => {
      dispatch(updateHomeScreenElement(campaignId, key, value));
    },
    [dispatch, campaignId]
  );
  if (!displayHomeScreen) {
    return null;
  }
  return (
    <StyledSegment
      style={{
        padding: svars.spaceLarge,
        opacity: displayHomeScreen ? 1 : 0.9,
      }}
    >
      <Form.Field>
        <RichTextEditor
          content={tagline}
          onChange={onElementChange('tagline')}
          placeholder={t`tagline-input-placeholder`}
          basic
          hasSubtitle
          light
          centered
          singleLine
          editable={displayHomeScreen}
        />
      </Form.Field>
      <Form.Field>
        <RichTextEditor
          content={title}
          onChange={onElementChange('title')}
          placeholder={t`title-input-placeholder`}
          basic
          isSubtitle
          heading
          centered
          singleLine
          editable={displayHomeScreen}
        />
      </Form.Field>
      <Form.Field>
        <RichTextEditor
          content={description}
          onChange={onElementChange('description')}
          placeholder={t`description-input-placeholder`}
          withTextAlign
          withLink
          editable={displayHomeScreen}
        />
      </Form.Field>
    </StyledSegment>
  );
}

export function EndScreenEditor() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const title = useSelector(titleEndScreenSelector(campaignId, true));
  const description = useSelector(
    descriptionEndScreenSelector(campaignId, true)
  );
  const onElementChange = useCallback(
    (key) => (value) => {
      dispatch(updateEndScreenElement(campaignId, key, value));
    },
    [dispatch, campaignId]
  );
  return (
    <StyledSegment>
      <Form.Field>
        <RichTextEditor
          content={title}
          onChange={onElementChange('title')}
          placeholder={t`title-input-placeholder`}
          isSubtitle
          heading
          centered
          singleLine
          basic
        />
      </Form.Field>
      <Form.Field>
        <RichTextEditor
          content={description}
          onChange={onElementChange('description')}
          placeholder={t`description-input-placeholder`}
          withTextAlign
          withLink
        />
      </Form.Field>
    </StyledSegment>
  );
}
