import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Divider, Form, Label } from 'semantic-ui-react';

import {
  activateQuestionScoring,
  setQuestionType,
  updateQuestionSetting,
} from 'actions/survey';
import {
  QUESTION_TYPES,
  getQuestionType,
  questionSelectorFactory,
} from 'selectors/survey';

import Header, { LightHeader } from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import LargeRadioButtonGroup from 'components/ui/button/LargeRadioButtonGroup';
import { Toggle } from 'components/ui/inputs/Checkbox';
import { DropdownTrigger, SelectBox } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput, TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const MULTICHOICE_DISPLAY_OPTIONS = [
  { icon: 'list', label: t`list`, value: 'vertical' },
  { icon: 'grid layout', label: t`grid`, value: 'horizontal' },
];

const TEXT_DISPLAY_OPTIONS = [
  {
    type: 'single-line',
    header: t`single-line-text-input`,
    help: t`single-line-text-input-help`,
  },
  {
    type: 'multi-line',
    header: t`multi-line-text-area`,
    help: t`multi-line-text-input-help`,
  },
];

function MultipleChoiceQuestionSettings({ question, campaignId, questionId }) {
  const dispatch = useDispatch();
  const displayOption = (question.vertical && 'vertical') || 'horizontal';

  const onSetQuestionMultiple = useCallback(() => {
    dispatch(
      updateQuestionSetting(
        campaignId,
        questionId,
        'multiple',
        !question?.multiple
      )
    );
  }, [dispatch, questionId, campaignId, question?.multiple]);
  const onDisplayOptionChange = useCallback(
    (displayValue) => () => {
      if (displayValue !== displayOption) {
        dispatch(
          updateQuestionSetting(
            campaignId,
            questionId,
            'vertical',
            displayValue === 'vertical'
          )
        );
      }
    },
    [dispatch, questionId, campaignId, displayOption]
  );
  const onQuestionWeightChange = useCallback(
    (e, { value }) =>
      dispatch(updateQuestionSetting(campaignId, questionId, 'weight', value)),
    [dispatch, questionId, campaignId]
  );
  const onActivateQuestionScoring = useCallback(() => {
    dispatch(
      activateQuestionScoring(campaignId, questionId, !question?.with_scoring)
    );
  }, [dispatch, questionId, campaignId, question?.with_scoring]);

  return (
    <>
      <Toggle
        toggleTestId="bo-campaign-multiple-toggle"
        label={t`multiple`}
        checked={!!question?.multiple}
        onChange={onSetQuestionMultiple}
      />
      <Divider fitted />

      <Form.Field style={{ margin: `0 ${svars.spaceMedium}` }}>
        <LightHeader style={{ paddingTop: svars.spaceMedium }}>
          <Trans id="question-options-display" />
        </LightHeader>
        <ButtonGroup fluid style={{ marginBottom: svars.spaceMediumLarge }}>
          {MULTICHOICE_DISPLAY_OPTIONS.map((item) => (
            <Button
              key={item.value}
              icon={item.icon}
              content={
                <Trans render={capitalizedTranslation} id={item.label} />
              }
              active={displayOption === item.value}
              onClick={onDisplayOptionChange(item.value)}
              data-testid={`bo-question-options-display-checkbox-${item.label}`}
            />
          ))}
        </ButtonGroup>
      </Form.Field>

      <Divider fitted />
      <Toggle
        toggleTestId="bo-campaign-use-in-scoring-toggle"
        label={t`use-in-scoring`}
        checked={question?.with_scoring}
        onChange={onActivateQuestionScoring}
      />
      {question?.with_scoring ? (
        <Form.Field style={{ margin: `0 ${svars.spaceMedium}` }}>
          <TextInput
            label={
              <Label
                content={t`question-weight`}
                style={{ background: svars.accentColorTransparent }}
              />
            }
            type="number"
            min="-100"
            max="100"
            step={1}
            style={{ marginTop: svars.spaceNormal, maxWidth: '200px' }}
            value={question?.weight}
            onChange={onQuestionWeightChange}
          />
        </Form.Field>
      ) : null}
    </>
  );
}

MultipleChoiceQuestionSettings.propTypes = {
  question: commonPropTypes.question.isRequired,
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};

function TextQuestionSettings({ question, campaignId, questionId }) {
  const dispatch = useDispatch();
  const displayOption = question?.short_text ? 'single-line' : 'multi-line';
  const onDisplayOptionChange = useCallback(
    (displayValue) => {
      if (displayValue !== displayOption) {
        dispatch(
          updateQuestionSetting(
            campaignId,
            questionId,
            'short_text',
            displayValue === 'single-line'
          )
        );
      }
    },
    [dispatch, questionId, campaignId, displayOption]
  );
  return (
    <Form.Field style={{ margin: `0 ${svars.spaceMedium}` }}>
      <LightHeader style={{ paddingTop: svars.spaceMedium }}>
        <Trans id="text-input-type" />
      </LightHeader>
      <LargeRadioButtonGroup
        items={TEXT_DISPLAY_OPTIONS}
        value={displayOption}
        onClick={onDisplayOptionChange}
      />
    </Form.Field>
  );
}

TextQuestionSettings.propTypes = {
  question: commonPropTypes.question.isRequired,
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};

function QuestionSettings({ questionId }) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const question = useSelector(
    questionSelectorFactory(campaignId, questionId, true)
  );
  const onSetQuestionBoolSetting = useCallback(
    (fieldName) => (checked) =>
      dispatch(
        updateQuestionSetting(campaignId, questionId, fieldName, checked)
      ),
    [dispatch, questionId, campaignId]
  );
  const onPageTitleChange = useCallback(
    (e, { value }) =>
      dispatch(
        updateQuestionSetting(campaignId, questionId, 'section_name', value)
      ),
    [dispatch, questionId, campaignId]
  );

  const onQuestionTypeChange = useCallback(
    (e, { value }) => dispatch(setQuestionType(campaignId, questionId, value)),
    [dispatch, questionId, campaignId]
  );

  if (!question) return null;
  const selectedDisplayOption = getQuestionType(question?.type);

  return (
    <>
      <Form.Field style={{ margin: `0 ${svars.spaceMedium}` }}>
        <Header>
          <Trans render={capitalizedTranslation} id="question-settings" />
        </Header>

        <SelectBox
          trigger={<DropdownTrigger value={selectedDisplayOption} />}
          style={{ marginBottom: svars.spaceMedium }}
          options={QUESTION_TYPES}
          searchable={false}
          value={question?.type}
          onChange={onQuestionTypeChange}
          withI18n
          selectBoxTestId="bo-campaign-question-settings"
        />
      </Form.Field>
      <Divider fitted />
      <Toggle
        toggleTestId="bo-campaign-required-toggle"
        label={t`required`}
        checked={question?.required}
        onChange={onSetQuestionBoolSetting('required')}
      />

      <Divider fitted />
      <Form.Field style={{ margin: `0 ${svars.spaceMedium}` }}>
        <LightHeader style={{ paddingTop: svars.spaceMedium }}>
          <Trans id="question-title" />
          <HelpTooltip help={t`question-title-help`} />
        </LightHeader>
        <LimitedTextInput
          maxCharacters={60}
          value={question?.section_name || ''}
          onChange={onPageTitleChange}
          style={{ marginBottom: svars.spaceMedium }}
        />
      </Form.Field>
      <Divider fitted />

      {(question?.type === 'MultiChoiceSurveyQuestion' && (
        <MultipleChoiceQuestionSettings
          question={question}
          campaignId={campaignId}
          questionId={questionId}
        />
      )) ||
        (question?.type === 'TextSurveyQuestion' && (
          <TextQuestionSettings
            question={question}
            campaignId={campaignId}
            questionId={questionId}
          />
        )) ||
        null}
    </>
  );
}

QuestionSettings.propTypes = {
  questionId: PropTypes.string,
};
QuestionSettings.defaultProps = {
  questionId: 0,
};

export default QuestionSettings;
