import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import { hasUnsavedChangesSelector } from 'selectors/campaign';

import CopyToClipboard from 'components/ui/CopyToClipboard';
import QrCode from 'components/ui/QrCode';
import { AnalyticsAwareButton } from 'components/ui/button';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { ButtonLineLayout } from 'components/ui/layout/Page';
import SidePane from 'components/ui/panels/SidePane';

import config from 'config';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const reloadIframe = () => {
  document.getElementById('preview_iframe').src += '';
};

function PreviewPane({ activeTab, previewActivated, onToggle }) {
  const { campaignId } = useParams();
  const [qrCodeIsActive, setQrCodeIsActive] = useState(false);
  const toggleActivateQrCode = () => setQrCodeIsActive(!qrCodeIsActive);

  const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);

  const previewUrl = `${config.PREVIEW_URL}${activeTab}?campaign=${campaignId}&preview=true`;
  return (
    <SidePane
      dimmed
      onToggle={onToggle}
      dimmerColor={svars.colorLightestGrey}
      animation="push"
      direction="right"
      visible={previewActivated}
    >
      <SidePane.Header
        title={
          <>
            <Trans render={capitalizedTranslation} id="preview" />
            <ButtonLineLayout
              style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                height: '100%',
              }}
            >
              <AnalyticsAwareHoverableIconButtonWithTooltip
                tooltipInverted={false}
                gaCategory="Campaign - customization"
                style={{ display: 'flex' }}
                gaAction="display preview qrcode"
                name="qrcode"
                onClick={toggleActivateQrCode}
                position="bottom right"
                mouseEnterDelay={500}
                help={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: svars.fontSizeLarger,
                      }}
                    >
                      <Trans render={capitalizedTranslation} id="link" />
                      <span>
                        <CopyToClipboard
                          text={previewUrl}
                          iconName="copy"
                          confirmedCopyMessage={t`copied`}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            margin: `${svars.spaceMedium} 0`,
                          }}
                        />
                        <AnalyticsAwareHoverableIconButtonWithTooltip
                          name="external"
                          help="open-in-new-tab"
                          onClick={() => window.open(previewUrl, '_blank')}
                          labelPosition="left"
                        />
                      </span>
                    </span>
                    <Divider />

                    <Trans id="scan-to-preview" />
                    <QrCode
                      value={previewUrl}
                      small
                      whiteAndBorder
                      style={{ marginTop: svars.spaceNormalLarge }}
                    />
                  </div>
                }
              />
              <AnalyticsAwareButton
                gaCategory="Campaign - customization"
                gaAction="Reset preview"
                inputComponent={SecondaryTabButton}
                style={{
                  minHeight: 0,
                  fontSize: svars.fontSizeBase,
                  marginLeft: svars.spaceMedium,
                }}
                content={t`reload`}
                icon="refresh"
                labelPosition="right"
                onClick={reloadIframe}
                data-testid="bo-campaign-reload-button"
              />
            </ButtonLineLayout>
          </>
        }
        onToggle={onToggle}
        gaCategory="Campaign - customization"
      />
      <SidePane.Body
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {hasUnsavedChanges && (
          <span style={{ color: svars.colorWarning }}>
            <Trans id="reload-preview-to-include-recent-changes" />
          </span>
        )}
        {previewActivated ? (
          <iframe
            width="339"
            height="735"
            src={previewUrl}
            title="preview"
            style={{
              maxWidth: '100%',
              borderRadius: svars.borderRadius,
              border: 'none',
              overflow: 'hidden',
              flexGrow: 1,
            }}
            id="preview_iframe"
          />
        ) : null}
      </SidePane.Body>
    </SidePane>
  );
}

PreviewPane.propTypes = {
  activeTab: PropTypes.string.isRequired,
  previewActivated: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

PreviewPane.defaultProps = { previewActivated: false };

export default PreviewPane;
