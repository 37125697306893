import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Divider } from 'semantic-ui-react';

import { setConceptHighlight, setConceptRandomization } from 'actions/campaign';
import {
  campaignOntologySelectorFactory,
  highlightConceptsSelectorFactory,
  randomizeSelectorFactory,
} from 'selectors/campaign';

import { LightHeader } from 'components/ui/Header';
import { StyledTabPane } from 'components/ui/Tab';
import { SelectBox } from 'components/ui/inputs/Dropdown';

import * as svars from 'assets/style/variables';

import CustomizationTabSegment from './CustomizationTabSegment';
import customizePageWordingFactory from './CustomizePageWording';
import TogglableSection from './TogglableSection';

const CustomizeWording = customizePageWordingFactory('concept_highlight', {
  title: t`title`,
  subTitle: t`subtitle`,
  noMore: t`no-more-highlight-to-answer`,
});
function GeneralCampaignCustomizeTab() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const randomize = useSelector(randomizeSelectorFactory(campaignId));
  const conceptHighlights = useSelector(
    highlightConceptsSelectorFactory(campaignId)
  );

  const { concepts: conceptOptions } = useSelector(
    campaignOntologySelectorFactory(campaignId)
  );

  const onToggleConceptRandomization = useCallback(
    () => dispatch(setConceptRandomization(campaignId, !randomize)),
    [campaignId, randomize]
  );
  const onSelectHighlightedConcept = useCallback(
    (e, { value }) => dispatch(setConceptHighlight(campaignId, value)),
    []
  );

  return (
    <StyledTabPane>
      <CustomizationTabSegment>
        <TogglableSection
          checked={randomize}
          onChange={onToggleConceptRandomization}
          title={t`concept-randomization`}
          subtitle={t`campaign-customization-randomization-help`}
          gaCategory="Campaign - customization"
          gaAction="Randomize concepts display"
          gaLabel={`${randomize}`}
        />
        <Divider />
        <CustomizeWording
          campaignId={campaignId}
          title={
            <>
              <LightHeader>
                <Trans id="concept-highlight" />
              </LightHeader>
              <Trans id="campaign-customization-concept-highlight-help" />
              <SelectBox
                placeholder={t`select-one-or-more-concepts`}
                fluid
                small="true"
                search
                selection
                multiple
                options={conceptOptions}
                onChange={onSelectHighlightedConcept}
                value={conceptHighlights}
                style={{
                  padding: `${svars.spaceNormal} 0`,
                }}
              />
            </>
          }
          fitted
        />
      </CustomizationTabSegment>
    </StyledTabPane>
  );
}

GeneralCampaignCustomizeTab.propTypes = {};

export default GeneralCampaignCustomizeTab;
