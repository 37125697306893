import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import {
  addUserFormElement,
  removeFormElement,
  updateFormElement,
} from 'actions/campaign';
import {
  formSelectorFactory,
  onGenerateTagSetsOptionsSelector,
} from 'selectors/campaign';

import FormItemsManagementList from 'components/ui/ManagementList/FormItemsManagementList';
import emptyPageUrl from 'components/ui/svg/undraw_link_shortener_mvf6.svg';

import { useMemoizedFactorySelector } from 'utils/hooks';

const CAMPAIGN_FIELD = 'url_form';

function UrlFormTabPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const urlForm = useMemoizedFactorySelector(
    formSelectorFactory,
    campaignId,
    CAMPAIGN_FIELD
  );
  const tagSetsOptions = useSelector(onGenerateTagSetsOptionsSelector);
  const onAddUrlFormElement = useCallback(
    (element) =>
      dispatch(addUserFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onRemoveUrlFormElement = useCallback(
    (element) =>
      dispatch(removeFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onUpdateFormElement = useCallback(
    (element) =>
      dispatch(updateFormElement(campaignId, CAMPAIGN_FIELD, element)),
    [campaignId]
  );
  return (
    <FormItemsManagementList
      items={urlForm}
      addItemLabel={t`add-url-form-element`}
      editItemLabel={t`edit-url-form-element`}
      restoreItemLabel={t`restore-url-form-element`}
      removeItemLabel={t`remove-url-form-element`}
      resetAllLabel={t`reset-url-form-element`}
      emptyListHeader={t`url-form-element.list-empty-header`}
      emptyListMessage={t`url-form-element.list-empty-message`}
      onAddElement={onAddUrlFormElement}
      onUpdateElement={onUpdateFormElement}
      onRemoveElement={onRemoveUrlFormElement}
      tagSetsOptions={tagSetsOptions}
      emptyDataIllustration={emptyPageUrl}
    />
  );
}

UrlFormTabPage.propTypes = {};

export default UrlFormTabPage;
