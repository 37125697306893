import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { debounce } from 'lodash';
import { Divider, Grid } from 'semantic-ui-react';

import {
  actionTypes as campaignActionTypes,
  checkIsAvailableCampaignName,
  createCampaign,
} from 'actions/campaign';
import { maybeFetchProductHierarchies } from 'actions/entities';
import { loadingStateSelector } from 'reducers/ui';
import { campaignItemsSelector } from 'selectors/campaign';
import { productHierarchiesItemsSelector } from 'selectors/entities';
import { isBwSelector } from 'selectors/user';

import * as Sentry from '@sentry/react';

import FacetCreateField from 'components/customer/home/view-facet-create/FacetCreateField';
import { LargeHeader } from 'components/ui/Header';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import LargeRadioButtonGroup from 'components/ui/button/LargeRadioButtonGroup';
import { SelectBox } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout, PageLayout } from 'components/ui/layout/Page';
import SvgContentUrl from 'components/ui/svg/undraw_content_vbqo.svg';

import * as svars from 'assets/style/variables';

const getCampaignLanguages = () => [
  { key: 'en', value: 'en', text: t`english`, flag: 'us' },
  { key: 'fr', value: 'fr', text: t`french`, flag: 'fr' },
  {
    key: 'zh',
    value: 'zh',
    text: t`simplified-chinese`,
    flag: 'cn',
  },
];

// Feedback or survey
const getCampaignTypes = (isBw) => [
  {
    type: 'feedback',
    header: t`feedback-app`,
    help: t`campaign-create.feedback-help`,
    disabled: !isBw,
  },
  {
    type: 'survey',
    header: t`survey`,
    help: t`campaign-create.survey-help`,
  },
];

const CAMPAIGN_CREATE_HELP_TEXT_IDS = [
  t`campaign-create-help.campaign-allow-feedback-collection`,
  t`campaign-create-help.campaign-can-be-shared`,
];

const CUSTOMIZATION_COPY_TYPE = 'copy';

const CUSTOMIZATION_TYPES = [
  {
    type: 'scratch',
    header: t`from-scratch`,
    help: t`campaign-create.from-scratch-help`,
  },
  {
    type: CUSTOMIZATION_COPY_TYPE,
    header: t`from-existing-campaign`,
    help: t`campaign-create.duplicate-help`,
  },
  {
    type: 'template',
    header: t`template`,
    help: t`campaign-create.from-template-help`,
    disabled: true,
  },
];

const campaignCreateLoadingSelector = loadingStateSelector([
  campaignActionTypes.CREATE_CAMPAIGN_REQUEST,
]);

function CreateCampaignPage() {
  const [searchParams] = useSearchParams();
  const initialCopyCampaignId = searchParams.get('copy') || null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignName, setCampaignName] = useState('');
  const [isCampaignNameValid, setIsCampaignValid] = useState(null);
  const [hierarchyId, setHierarchyId] = useState(null);
  const [campaignType, setCampaignType] = useState(
    initialCopyCampaignId ? 'survey' : null
  );
  const [customizationType, setCustomizationType] = useState(
    initialCopyCampaignId
      ? CUSTOMIZATION_COPY_TYPE
      : CUSTOMIZATION_TYPES[0].type
  );
  const [campaignIdToCopy, setCampaignIdToCopy] = useState(
    initialCopyCampaignId
  );

  const campaignLanguages = useMemo(() => getCampaignLanguages(), []);

  const [language, setLanguage] = useState(campaignLanguages[0].value);

  useEffect(() => dispatch(maybeFetchProductHierarchies()), []);

  const productHierarchiesItems = useSelector(productHierarchiesItemsSelector);
  const isCampaignCreateLoading = useSelector(campaignCreateLoadingSelector);
  const isBw = useSelector(isBwSelector);
  const campaigns = useSelector(campaignItemsSelector);

  const campaignTypes = useMemo(() => getCampaignTypes(isBw), [isBw]);

  const validateSlugName = useCallback(
    debounce(
      (value) => {
        if (value) {
          checkIsAvailableCampaignName(value, setIsCampaignValid);
        }
      },
      450,
      { leading: false, trailing: true }
    ),
    [setIsCampaignValid]
  );
  const onNameChange = useCallback(({ target: { value } }) => {
    setCampaignName(value);
    if (value !== '') {
      validateSlugName(value);
    }
  }, []);
  const onSelectCampaignType = useCallback(
    (value) => {
      if (value !== campaignType) {
        setCampaignType(value);
      }
    },
    [campaignType, campaignIdToCopy]
  );
  const onSelectCustomizationType = useCallback(
    (value) => {
      if (value !== customizationType) {
        setCustomizationType(value);
        if (campaignIdToCopy) setCampaignIdToCopy('');
      }
    },
    [customizationType, campaignIdToCopy]
  );

  const onHierarchyChange = useCallback((e, data) => {
    if (data.value) {
      setHierarchyId(data.value);
    } else {
      setHierarchyId(null);
    }
  }, []);
  const onLanguageChange = useCallback((e, data) => {
    if (data.value) {
      setLanguage(data.value);
    } else {
      setLanguage(null);
    }
  }, []);

  const onValidateCreate = useCallback(async () => {
    const campaignId = await dispatch(
      createCampaign(
        campaignType,
        campaignName,
        hierarchyId,
        language,
        campaignIdToCopy
      )
    );
    if (typeof campaignId === 'string') {
      navigate(`../${campaignId}/customize`);
    } else {
      // Error creating campaign
      Sentry.captureException(`Could not create campaign : ${campaignId}`);
    }
  }, [campaignName, hierarchyId, language, campaignType, campaignIdToCopy]);
  return (
    <PageLayout simple>
      <Grid
        style={{
          flexGrow: 1,
          padding: svars.spaceMediumLarge,
          overflowY: 'auto',
        }}
      >
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={9}>
            <LargeHeader>
              <Trans id="create-a-new-campaign" />
            </LargeHeader>
            <div style={{ maxWidth: svars.textMaxWidth }}>
              {CAMPAIGN_CREATE_HELP_TEXT_IDS.map((item) => (
                <p key={`text-it-${item.id || item}`}>
                  <Trans id={item} />
                </p>
              ))}
            </div>
          </Grid.Column>
          <Grid.Column
            width={7}
            floated="right"
            textAlign="right"
            verticalAlign="bottom"
          >
            <img
              style={{
                maxHeight: '15rem',
                paddingRight: svars.spaceLarge,
              }}
              src={SvgContentUrl}
              alt="star"
            />
          </Grid.Column>
        </Grid.Row>

        <FacetCreateField
          input={
            <LimitedTextInput
              style={{
                width: '100%',
                fontWeight: svars.fontWeightBold,
                fontSize: svars.fontSizeXLarge,
              }}
              placeholder={t`campaign-create.title-placeholder`}
              value={campaignName}
              onChange={onNameChange}
              maxCharacters={80}
              data-testid="bo-create-campaign-name-input"
            />
          }
          errorMessage={
            campaignName && isCampaignNameValid === false
              ? t`campaign-name-already-used-choose-another`
              : null
          }
          inputWidth={15}
        />
        <FacetCreateField
          name={t`campaign-type`}
          inputWidth={16}
          input={
            <LargeRadioButtonGroup
              inline
              items={campaignTypes}
              value={campaignType}
              onClick={onSelectCampaignType}
              largeRadioButtonTestId="bo-campaign-type-checkbox"
            />
          }
          data-testid="bo-campaign-creation-campaign-type-field"
        />
        {
          // Display product(s) service(s) selection only if campaign type is feedback
          (campaignType === 'feedback' && (
            <FacetCreateField
              name={t`product(s)-service(s)`}
              input={
                <SelectBox
                  placeholder={t`select-a-product(s)-service(s)`}
                  loading={isCampaignCreateLoading}
                  options={productHierarchiesItems || []}
                  onChange={onHierarchyChange}
                  value={hierarchyId}
                  testid="bo-campaign-creation-product-list-input"
                />
              }
              helpMessage={[t`campaign-create.product-service-help`]}
              data-testid="bo-campaign-creation-product-selection-field"
            />
          )) ||
            (campaignType === 'survey' && (
              <FacetCreateField
                name={t`customization`}
                inputWidth={16}
                input={
                  <LargeRadioButtonGroup
                    inline
                    items={CUSTOMIZATION_TYPES}
                    value={customizationType}
                    onClick={onSelectCustomizationType}
                    largeRadioButtonTestId="bo-customization-type-checkbox"
                  />
                }
                data-testid="bo-campaign-creation-customization-type-field"
              />
            ))
        }
        {customizationType === CUSTOMIZATION_COPY_TYPE && (
          <FacetCreateField
            name={t`campaign-to-copy`}
            input={
              <SelectBox
                placeholder={t`select-a-campaign`}
                loading={isCampaignCreateLoading}
                options={campaigns}
                upward
                onChange={(e, data) => setCampaignIdToCopy(data.value)}
                value={campaignIdToCopy}
                testid="bo-campaign-creation-campaign-to-copy-list-input"
              />
            }
            helpMessage={[t`campaign-create.campaign-to-copy-help`]}
            data-testid="bo-campaign-creation-campaign-to-copy-selection-field"
          />
        )}

        <FacetCreateField
          name={t`language`}
          input={
            <SelectBox
              upward
              placeholder={t`choose-a-language`}
              loading={isCampaignCreateLoading}
              options={campaignLanguages}
              onChange={onLanguageChange}
              value={language}
              selectBoxTestId={`${'bo-campaign-idiom-selector'}-${language}`}
            />
          }
          helpMessage={t`campaign-create.language-help`}
        />
      </Grid>
      <Divider />
      <ButtonLineLayout padded>
        <AnalyticsAwareButton
          gaCategory="Campaign management"
          gaAction="Campaign creation"
          gaLabel="cancel"
          inputComponent={ButtonTransparentDanger}
          onClick={() => navigate('..')}
          data-testid="bo-campaign-creation-cancel-button"
        >
          <Trans id="cancel" />
        </AnalyticsAwareButton>
        <AnalyticsAwareButton
          gaCategory="Campaign management"
          gaAction="Campaign creation"
          gaLabel="create"
          inputComponent={ButtonAccent}
          onClick={onValidateCreate}
          disabled={
            isCampaignCreateLoading ||
            !(isCampaignNameValid === true && campaignType) ||
            (customizationType === CUSTOMIZATION_COPY_TYPE && !campaignIdToCopy)
          }
          loading={isCampaignCreateLoading}
          style={{ marginLeft: svars.spaceMediumLarge }}
          data-testid="bo-campaign-creation-create-button"
        >
          <Trans id="create" />
        </AnalyticsAwareButton>
      </ButtonLineLayout>
    </PageLayout>
  );
}

CreateCampaignPage.propTypes = {};

export default CreateCampaignPage;
