import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Dimmer, Segment } from 'semantic-ui-react';

import {
  closePreview,
  deactivateRestoreDiff,
  deletePreviewConfig,
  openPreview,
  restoreCachedDiff,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import {
  campaignConfigurationLoadingSelector,
  hasUnsavedChangesSelector,
  previewActivatedSelector,
  restorationActivatedSelector,
} from 'selectors/campaign';
import { isFeedbackCampaignSelector } from 'selectors/survey';

import BeatingLoader from 'components/ui/BeatingLoader';
import {
  ButtonSecondaryAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';

import useInterval from 'utils/hooks';

import * as svars from 'assets/style/variables';

import PreviewPane from './PreviewPane';
import SaveControl from './SaveControl';
import CustomizationTabs, {
  FEEDBACK_CUSTOMIZATION_PANES,
  SURVEY_CUSTOMIZATION_PANES,
} from './useCustomizationTabs';

const redirectToPane = (isFeedbackCampaign, pathname) => {
  const panes = isFeedbackCampaign
    ? FEEDBACK_CUSTOMIZATION_PANES
    : SURVEY_CUSTOMIZATION_PANES;

  let redirectTo = null;
  const firstPanePath = panes?.[0]?.to;

  if (pathname.endsWith('/customize')) {
    redirectTo = firstPanePath;
  } else if (panes.every((pane) => !pathname.endsWith(pane.to))) {
    redirectTo = `./${firstPanePath}`;
  }
  return redirectTo;
};

function CustomizeCampaignLayout() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState('');
  const restorationActivated = useSelector(restorationActivatedSelector);
  const campaignConfigurationIsLoading = useSelector(
    campaignConfigurationLoadingSelector
  );
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const previewActivated = useSelector(previewActivatedSelector);
  const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);

  const onTogglePreview = useCallback(async () => {
    if (previewActivated) {
      dispatch(closePreview());
    } else {
      await dispatch(saveCampaignConfigurationAsDraft(campaignId));
      dispatch(openPreview());
    }
  }, [campaignId, previewActivated, dispatch]);

  const savePreviewChanges = useCallback(() => {
    if (hasUnsavedChanges && !campaignConfigurationIsLoading) {
      dispatch(saveCampaignConfigurationAsDraft(campaignId));
    }
  }, [campaignId, hasUnsavedChanges, campaignConfigurationIsLoading, dispatch]);

  useInterval(savePreviewChanges, 2000);

  useEffect(() => {
    // We add an event listener on beforeunload to send the diff
    // to the backend and not losing the customization

    window.addEventListener('beforeunload', savePreviewChanges);
    return () => {
      setTimeout(() => {}, 1000);
      window.removeEventListener('beforeunload', savePreviewChanges);
    };
  }, []);

  const redirectTo = redirectToPane(isFeedbackCampaign, pathname);
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }
  return (
    <>
      <CustomizationTabs campaignId={campaignId} setActiveTab={setActiveTab}>
        <SaveControl pageRoute={activeTab} campaignId={campaignId} />
      </CustomizationTabs>
      {campaignConfigurationIsLoading ? <BeatingLoader absolute /> : null}
      <PreviewPane
        activeTab={activeTab}
        onToggle={onTogglePreview}
        previewActivated={previewActivated}
      />
      <Outlet />
      {restorationActivated && (
        <Dimmer
          active
          page
          blurring
          inverted
          style={{ position: 'absolute', justifyContent: 'flex-end' }}
        >
          <Segment
            style={{
              maxWidth: '700px',
              padding: `${svars.spaceMediumLarge} ${svars.spaceNormalLarge} ${svars.spaceMedium} ${svars.spaceMedium}`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              margin: `${svars.spaceMedium} auto`,
            }}
          >
            <span style={{ flexGrow: 1, marginBottom: svars.spaceMediumLarge }}>
              <Trans id="campaign-customization.saved-draft-restore-message" />
            </span>
            <div
              style={{
                alignSelf: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <ButtonTransparentAccent
                onClick={() => {
                  dispatch(deactivateRestoreDiff());
                  dispatch(deletePreviewConfig(campaignId));
                }}
              >
                <Trans id="forget-changes" />
              </ButtonTransparentAccent>
              <ButtonSecondaryAccent
                onClick={() => dispatch(restoreCachedDiff(campaignId))}
              >
                <Trans id="restore-changes" />
              </ButtonSecondaryAccent>
            </div>
          </Segment>
        </Dimmer>
      )}
      {/* <TimeoutLogic
        logOutMethod={() => {
          dispatch(deletePreviewConfig(campaignId));
          navigate('/campaign');
        }}
        automaticLogOutMethod={() => {
          dispatch(saveCampaignConfigurationAsDraft(campaignId));
          navigate('/campaign');
        }}
        firstDelay={6000000}
        secondDelay={60000}
      /> */}
    </>
  );
}

CustomizeCampaignLayout.propTypes = {};

export default CustomizeCampaignLayout;
