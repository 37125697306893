import { t } from '@lingui/macro';

import customizePageWordingFactory from './CustomizePageWording';

const FEEDBACK_HOME_WORDING = {
  title: t`home-title`,
  subtitle: t`home-subtitle`,
  secondSubtitle: t`home-second-subtitle`,
  ctaText: t`home-cta-button`,
  consultFAQPrefix: t`you-have-questions?`,
  consultFAQ: t`check-faq`,
};
export const CustomizeHomeTab = customizePageWordingFactory(
  'home',
  FEEDBACK_HOME_WORDING
);

const FEEDBACK_SEARCH_WORDING = {
  keyword_examples: t`keyword-examples`,
  title: t`search-page-title`,
  inputPlaceholder: t`input-placeholder`,
  noResults: t`no-results`,
  leaveQuickFeedback: t`leave-quick-feedback`,
  clearSearch: t`clear-search`,
  searchAConcept: t`search-a-concept`,
  searchAConceptSubtitle: t`search-a-concept-subtitle`,
};
export const CustomizeSearchTab = customizePageWordingFactory(
  'search',
  FEEDBACK_SEARCH_WORDING
);

const FEEDBACK_FORM_WORDING = {
  feedbackOn: t`feedback-on`,
  satisfactionLevelQuestion: t`satisfaction-level-question`,
  openTextQuestion: t`open-text-question`,
  openTextPlaceholder: t`open-text-placeholder`,
  emptyHelper: t`empty-helper`,
  privacyPolicy: t`privacy-policy-title`,
  privacyNoticePrefix: t`privacy-noticeprefix`,
  ctaText: t`publish-feedback-cta-text`,
};
export const CustomizeFeedbackFormTab = customizePageWordingFactory(
  'feedback',
  FEEDBACK_FORM_WORDING
);

const FEEDBACK_PUBLISHED_WORDING = {
  title: t`title`,
  subtitle: t`subtitle`,
  secondSubtitle: t`second-subtitle`,
  allConceptsClearedSubtitle: t`all-concepts-cleared-subtitle`,
  searchCtaText: t`search-cta-text`,
};
export const CustomizePublishedTab = customizePageWordingFactory(
  'published',
  FEEDBACK_PUBLISHED_WORDING
);
